/**
 * @description 处理客户端登陆、登出、保活。版本相关逻辑
 * @author 178730 <jin_cong2@dahuatech.com>
 * @date 2021-04-27
 */

import JSEncrypt from "../../node_modules/jsencrypt/bin/jsencrypt.min.js";
const encrypt = new JSEncrypt();

export default {
    /**
     * @description 心跳
     */
    heartbeat() {
        const config = this.config;
        const { loginIp, userCode } = config;
        this.postMessage("heartbeat", {
            loginIp,
            userCode
        });
    },
    /**
     * @description 登录客户端
     */
    loginClient() {
        this.loginEnd = false;
        this.loginStartTime = Date.now();
        const config = this.config;
        console.log(
            `ws-basic:loginClient start,params loginStartTime:${this.loginStartTime},params config:`
        );
        console.log(config);
        let {
            loginIp,
            userCode,
            verifyCode,
            token,
            tokenHeartbeatTime,
            loginPort,
            userName,
            userPwd,
            https,
            browser: { name, version, platform },
            //报警推送启停开关：1为开启推送，0为关闭推送，默认为0
            alarmDlgEnable = 0
        } = config;
        // token登陆的username通过token截取
        // if(token && token.split('_')[0]) {
        // 	userName = token.split('_')[0];
        // }
        // 登陆确保有用户名+token/pwd
        if (!(userName && (token || userPwd))) {
            this.loginEnd = true;
            return false;
        }

        if (typeof https === "undefined") {
            if (location.protocol === "http:") {
                https = Number(0);
            } else if (location.protocol === "https:") {
                https = 1;
            } else {
                https = -1;
            }
        }
        encrypt.setPublicKey(this.publicKey);
        const params = {
            loginIp,
            params: {
                loginPort,
                token: token ? token : "", // token优先于密码
                userPwd: token ? "" : encrypt.encrypt(userPwd),
                tokenHeartbeatTime,
                userName,
                https,
                browser: name,
                version,
                platform,
                alarmDlgEnable
            },
            userCode,
            verifyCode
        };
        // 心跳保活
        this._heartbeat();
        this.postMessage("login", params);
    },
    /**
     * @description 登出客户端
     */
    logoutClient() {
        const config = this.config;
        const { loginIp, userCode } = config;
        // 清除保活
        clearTimeout(this.heartbeatTimer);
        this.postMessage("logout", {
            loginIp,
            userCode
        });
        this.config.userName = "";
    },
    /**
     * @description 获取客户端版本
     */
    getVersion() {
        // 缓存中已经存在当前客户端版本时取消版本获取
        if (this.currentDssVersion) {
            return;
        }
        const config = this.config;
        const { loginIp, userCode } = config;

        // 获取客户端版本结束标准置为false
        this.getVersionEnd = false;

        //document.title += "-" + userCode;//新增 国产化需要获取该userCode作为网页唯一标识，非国产化不用。
        this.postMessage("getVersion", {
            loginIp,
            userCode
        });
    }
};
