export default {
    /**
     * @description 获取版本信息结果
     * 降低轻客户端版本升级提醒频繁度，取消版本比对方法调用 @2023-1-31 by 周伟
     */
    getVersionResult(data) {
        this.getVersionEnd = true;
        this.currentDssVersion = data;
    },

    /**
     * @description 客户端截图，以图搜图
     */
    goToSearchPic(data) {
        console.log("ws-basic:goToSearchPic start,param data:", data);
        if (
            data &&
            data.params &&
            data.params.array &&
            Array.isArray(data.params.array)
        ) {
            let menuCode = data.params.destinationCode;
            let param = {};
            param[menuCode] = data.params.array[0];
            // 这个if判断从逻辑上来说没有作用，而且产生了无法跳转的问题，因此注掉
            // if (frames[`nav-frame-${menuCode}`]) {
            //     frames[`nav-frame-${menuCode}`].$nav.push({
            //       code: menuCode,
            //       params: param
            //     })
            //   } else {
            window &&
                window["$nav"] &&
                window["$nav"].push({
                    code: menuCode,
                    params: param
                });
            //   }
        } else {
            this.Vue.prototype.$Message.info("未查询到数据");
        }
    },
    /**
     * @description 登陆结果
     */
    loginState(data) {
        if (data) {
            // 登录成功
            this.isLoginSuccess = data;
            // 登录结束
            this.loginEnd = true;
            if (typeof this.callback.loginResult === "function") {
                this.callback.loginResult.call(this, this.isLoginSuccess);
            }
        } else {
            // 登录失败
            this.loginFailCount++;
            if (this.loginFailCount < this.reLoginCount + 1) {
                // 登录未到最大次数
                this.loginClient();
            } else {
                // 登录达到最大次数
                // 登录结束
                this.loginEnd = true;
                // 重置登录失败次数
                this.loginFailCount = 0;

                this.isLoginSuccess = data;
                if (typeof this.callback.loginResult === "function") {
                    this.callback.loginResult.call(this, this.isLoginSuccess);
                }
            }
        }
    },
    /**
     * @description 创建控件结果
     */
    createCtrlResult(data) {
        // todo 修改ctrls 和 ids
        let _data = data;
        let failIds = [];
        _data.forEach(e => {
            if (e.result !== 0) {
                failIds.push(e.ctrlCode);
            }
        });
        this.ids.forEach((e, i) => {
            if (failIds.indexOf(e) !== -1) {
                this.ids.splice(i, 1);
                this.ctrls.splice(i, 1);
            }
        });
    },
    /**
     * @description 销毁控件结果
     */
    destroyCtrlResult(data) {
        // 未发现这种情况，不好模拟，暂未调试
        // todo 修改ctrls 和 ids
        console.log("ws-basic:destroyCtrlResult start,param data:", data);
    }
};
