import { Create, Position, Record, CtrlRecord, download } from '../types/index';
import { checkDomExit, getScrollInfo, getDocument, getDomById, isIframe } from '../utils/domOperate';
export default {
	
	/**
	 * @description 提交浏览器信息
	 */
	browserInfo() {
		const config = this.config;
		const { loginIp, userCode } = config;
		const {
			browser: { name, version, platform }
		} = config;
		this.postMessage('browserInfo', {
			loginIp,
			userCode,
			params: {
				browser: name,
				version,
				platform
			}
		});
	},

	/**
	 * @description 浏览器切换显隐
	 */
	webVisibilityChange() {
		const config = this.config;
		const { loginIp, userCode } = config;
		this.postMessage('webVisibilityChange', {
			loginIp,
			userCode,
			params: {
				hidden: getDocument().hidden
			}
		});
	},

	/**
	 * @description 受信任站点
	 */
	trusteSite(http: String) {
		const config = this.config;
		const { loginIp, userCode } = config;
		this.postMessage('trusteSite', {
			loginIp,
			userCode,
			params: {
				trusteSite: http
			}
		});
	},

	/**
	 * @description 设置下载路径
	 * @params {String} path 下载路径地址
	 */
	setDownloadPath(path: String) {
		const config = this.config;
		const { loginIp, userCode } = config;
		return this.postMessage('setDownloadPath', {
			loginIp,
			userCode,
			params: {
				downloadPath: path
			}
		});
	},

	/**
	 * @description 下载视频
	 * @params {Array} downloads 下载录像参数
	 * {
			channelName: '11111',
			channelId: '1DSNHDHF654DS',
			beginTime: 1602559308127,
			endTime: 1602559308227,
			format: 1,
			sourceType: 1,
			streamType: 1
		}
	 * @params {Boolean} visible 是否显示下载窗口
	 */
	downloadVideo(downloads: Array<download>, visible) {
		const config = this.config;
		const { loginIp, userCode } = config;
		return this.postMessage('downloadVideo', {
			loginIp,
			userCode,
			params: {
				array: downloads,
				visible: visible
			}
		});
	},

	/**
	 * @description 创建控件
	 *
	 * @param {Array} documents
	 * {
	 *  array: [{
	 *     ctrlCode: '11',
	 * 	   ctrlType: 'playerCtrl'
	 * }]
	 * }
	 */
	createCtrl(widgets: Array<Create>) {
		console.log('ws-basic:createCtrl start', widgets);
		// 未安装客户端且重连失败，提示底部信息
		if (!this.isConnectSuccessQt && this.hasFinishFirstConnectProcess) {
			// 返回完成，不返回reject
			return Promise.reject('未安装客户端且重连失败');
		}
		// 连接成功之后，版本不一致比较
		this.isConnectSuccessQt && this.compareVersion();
		const _this = this;
		const config = this.config;
		const { loginIp, userCode } = config;
		let widgetParams: Array<any> = [];

		if (widgets.length === 0) {
			console.error('param widget must has a ctrlCode.');
			return false;
		}
		let titleID = '';
		// 国产化时需要增加 titleID 保证浏览器tab唯一性
		if (!(/win32|win64|wow32|wow64/.test(navigator.userAgent.toLowerCase()))) {
			try {
				titleID = window.top.document.title.split('-')[window.top.document.title.split('-').length - 1];
			} catch (error) {
				console.warn('暂不支持跨域使用');
			}
		}
		widgets.forEach(item => {
			//重复创建ctrlCode的控件时，把之前的控件实例销毁掉
			if (_this.ids.includes(item.ctrlCode)) {
				_this.destroyCtrl([item.ctrlCode]);
			}
			let widgetParam: any = {};
			const {
				ctrlType,
				ctrlCode,
				ctrlProperty,
				visible,
				cutList,
				domId,
				PlaybackContent
			} = item;
			const domTarget = getDocument().getElementById(domId);
			if (!domTarget) {
				console.warn(`can not find dom by id ${domId}`);
				// throw new Error(`can not find dom by id ${domId}`);
			}

			let domInfo = getDomById(domId, this.manualSetIframePosition && this.iframeToTopPosition);
			let scrollInfo = getScrollInfo();

			// 停止观测
			// observer.disconnect();
			widgetParam['ctrlType'] = ctrlType;
			widgetParam['visible'] = visible;
			widgetParam['ctrlCode'] = ctrlCode;
			widgetParam['ctrlProperty'] = ctrlProperty;
			widgetParam['cutList'] = cutList;
			widgetParam['posX'] = domInfo.x;
			widgetParam['posY'] = domInfo.y;
			widgetParam['width'] = domInfo.width;
			widgetParam['height'] = domInfo.height;
			widgetParam['ratio'] = scrollInfo.ratio;
			widgetParam['scrollX'] = 0;
			widgetParam['scrollY'] = 0;
			widgetParam['scrollXH'] = scrollInfo.scrollXH;
			widgetParam['scrollYW'] = scrollInfo.scrollYW;
			widgetParam['viewWidth'] = isIframe()? parent.window.innerWidth : window.innerWidth; //国产化新增
			widgetParam['viewHeight'] =  isIframe()? parent.window.innerHeight : window.innerHeight; //国产化新增
			// widgetParam['observer'] = observer;
			widgetParam['domId'] = domId;
			widgetParam['PlaybackContent'] = PlaybackContent;

			// widgetParam['domTarget'] = domTarget;
			widgetParams.push(widgetParam);
			_this.ctrls.push(widgetParam);
			_this.ids.push(ctrlCode);
		});

		return this.postMessage('createCtrl', {
			// todo 回退uuidmap/ctrls处理
			loginIp,
			userCode,
			titleID,
			params: {
				array: widgetParams
			}
		});
	},

	/**
	 * @description 设置可见
	 *
	 * @param {Array} ctrls
	 * {
	 *  array: [{
	 *      ctrlCode: "bf662065-3bda-4cb7-8fdd-b6e13997d84f"
	 *		visible: true
	 * }]
	 * }
	 */
	setCtrlVisible(
		ctrls: Array<{
			ctrlCode: String;
			visible: Boolean;
		}>
	) {
		// 未安装客户端且重连失败，提示底部信息
		if (!this.isConnectSuccessQt && this.hasFinishFirstConnectProcess) {
			return Promise.reject('未安装客户端且重连失败');
		}
		const _this = this;
		const config = this.config;
		const { loginIp, userCode } = config;
		let ctrlParams: Array<Object> = [];
		if (ctrls.length === 0) {
			return false;
		}
		ctrls.forEach(item => {
			// if (item.visible) {
			// 	// 连接成功之后且是显示控件，则进行版本不一致比较
			// 	this.isConnectSuccessQt && this.compareVersion();
			// }
			const ctrlParam: any = {};
			if (!_this.ids.includes(item.ctrlCode)) {
				return false;
			}
			const ctrl = _this.ctrls.find((ctrl: { ctrlCode: String }) => {
				return ctrl.ctrlCode === item.ctrlCode;
			});
			//更新实例本身的显隐
			ctrl['visible'] = item.visible;
			ctrlParam['ctrlCode'] = item.ctrlCode;
			ctrlParam['visible'] = item.visible;
			ctrlParam['viewWidth'] = isIframe()? parent.window.innerWidth : window.innerWidth;	//国产化新增
			ctrlParam['viewHeight'] = isIframe()? parent.window.innerHeight : window.innerHeight;; //国产化新增
			ctrlParams.push(ctrlParam);
		});
		return this.postMessage('setCtrlVisible', {
			loginIp,
			userCode,
			params: {
				array: ctrlParams
			}
		});
	},

	/**
	 * @description 设置控件属性--位置，剪裁
	 *
	 * @param {Array} positions
	 * {
	 *  array: [{
	 *     id: 1,
	 * 	   posX: 100,
	 * 	   posY: 200
	 * }]
	 * }
	 */
	setCtrlPos(positions: Array<Position>) {
		const _this = this;
		const config = this.config;
		const { loginIp, userCode } = config;
		let widgetParams: Array<any> = [];
		if (positions.length === 0) {
			throw new Error('setCtrlPos must has a Id.');
		}
		positions.forEach(item => {
			if (!_this.ids.includes(item.ctrlCode)) {
				throw new Error(`widget by id:${item.ctrlCode} has not been created.`);
			}
			const ctrl = _this.ctrls.find((ctrl: { ctrlCode: any }) => {
				return ctrl.ctrlCode === item.ctrlCode;
			});
			if (!ctrl) {
				throw new Error(`can not find ctrl by id:${item.ctrlCode}.`);
			}
			// 待设置属性列表
			let propList = ['posX', 'posY', 'width', 'height', 'ratio', 'scrollX', 'scrollY', 'scrollXH', 'scrollYW', 'cutList', 'viewWidth', 'viewHeight'];
			// 设置控件属性
			function setProp(ctrl, item, prop) {
				item.hasOwnProperty(prop) ? ctrl[prop] = item[prop] : '';
			}
			propList.forEach(e => {
				setProp(ctrl, item, e);
			});
			const widgetParam = ctrl;
			widgetParams.push(widgetParam);
		});

		return this.postMessage('setCtrlPos', {
			loginIp,
			userCode,
			params: {
				array: widgetParams
			}
		});
	},

	/**
	 * @description 重新定位控件位置
	 * @params {Array} ['ctrlId1', 'ctrlId2']
	 */
	reLocatedPosition(ctrlCode?: Array<String>) {
		let _ctrlList = [];
		if (ctrlCode && ctrlCode.length > 0) {
			this.ctrls.forEach(e => {
				if (ctrlCode.includes(e.ctrlCode)) {
					_ctrlList.push(e);
				}
			})
		}
		let _needLocationList = _ctrlList.length > 0 ? _ctrlList : this.ctrls;
		if (_needLocationList.length === 0) return false;
		let exitParams = _needLocationList.filter(e => {
			return (e.domId && checkDomExit(e.domId, false));
		})
		let params = exitParams.map(e => {
			let domInfo = getDomById(e.domId, this.manualSetIframePosition && this.iframeToTopPosition);
			let scrollInfo = getScrollInfo();
			let ctrlCode = e.ctrlCode;
			let cutList = e.cutList;
			let posX = domInfo.x;
			let posY = domInfo.y;
			let width = domInfo.width;
			let height = domInfo.height;
			let ratio = scrollInfo.ratio;
			let scrollX = 0;
			let scrollY = 0;
			let scrollXH = scrollInfo.scrollXH;
			let scrollYW = scrollInfo.scrollYW;
			let viewWidth = isIframe()? parent.window.innerWidth : window.innerWidth;	//国产化新增
			let viewHeight = isIframe()? parent.window.innerHeight : window.innerHeight;	//国产化新增
			return { ctrlCode, cutList, posX, posY, width, height, ratio, scrollX, scrollY, scrollXH, scrollYW, viewWidth, viewHeight };
		});
		if (params.length) {
			return this.setCtrlPos(params);
		} else {
			return new Promise((resolve) => { resolve(true) });
		}
	},

	/**
	 * @description 销毁控件
	 *
	 * @param {Array} ids
	 * {
	 *  array: ['1','2']
	 * }
	 */
	destroyCtrl(ids: Array<String>) {
		if (ids.length === 0) {
			return false;
		}
		const _this = this;
		const config = this.config;
		const { loginIp, userCode } = config;
		const _ids = [...ids];
		// 删除缓存中的控件和ids
		const params = ids.map(item => {
			return { ctrlCode: item };
		});
		_ids.forEach(id => {
			// if (!_this.ids.includes(id)) {
			// 	throw new Error(`element by id:${id} has not been created.`);
			// }
			for (var i = 0; i < _this.ctrls.length; i++) {
				if (_this.ctrls[i].ctrlCode === id) {
					_this.ctrls.splice(i, 1);
					_this.ids.splice(i, 1);
				}
			}
		});
		return this.postMessage('destroyCtrl', {
			loginIp,
			userCode,
			params: {
				array: params
			}
		});
	},

	/**
	 * @description 播放实时视频
	 *
	 * @param {Object}
	 * {
	 * 	 "ctrlCode":"ctrlCode",// 唯一标识符
	 *   "channelIds": ["AcqRGNrqA1B15042F6AMEO"]// 播放的通道编码
	 * }
	 */
	openCtrlPreview(obj: { ctrlCode: String; channelIds: Array<String> }) {
		const config = this.config;
		const { loginIp, userCode } = config;
		if (!obj.ctrlCode || !this.ids.includes(obj.ctrlCode)) {
			throw new Error(`widget by id:${obj.ctrlCode} has not been created.`);
		}
		if (obj.channelIds.length === 0) {
			return false;
		}
		const arr = obj.channelIds.map((i: any) => {
			return { channelId: i };
		});
		return this.postMessage('openCtrlPreview', {
			loginIp,
			userCode,
			params: {
				array: [
					{
						ctrlCode: obj.ctrlCode,
						array: arr
					}
				]
			}
		});
	},

	/**
	 * @description 播放录像
	 *
	 * @param {Object}
	 *  [
	 *       {
	 *			"ctrlCode":"code0",
	 *			"array":
	 *           [{
	 *           	"beginTime": "2019-02-21 00:00:00",
	 *           	"channelId": "AcqRGNrqA1B15042F6AMEO",
	 *           	"endTime": "2019-02-21 23:59:59"
	 *			}]
	 *       }
	 *   ]
	 */
	openCtrlRecord(arr: Array<CtrlRecord>) {
		const config = this.config;
		var obj = arr[0];
		if (!obj.ctrlCode || !this.ids.includes(obj.ctrlCode)) {
			throw new Error(`widget by id:${obj.ctrlCode} has not been created.`);
		}
		const { loginIp, userCode } = config;
		return this.postMessage('openCtrlRecord', {
			loginIp,
			userCode,
			params: {
				array: arr
			}
		});
	},

	/**
	 * @description 关闭单个控件所有视频
	 *
	 * @param {Object}
	 * {
	 * 	 "ctrlCode":["uuidcode"] //唯一标识符
	 * }
	 */
	closeCtrlVideo(arr: [String]) {
		const config = this.config;
		const { loginIp, userCode } = config;
		const arrParams = arr.map(item => {
			return { ctrlCode: item };
		});

		return this.postMessage('closeCtrlVideo', {
			loginIp,
			userCode,
			params: {
				array: arrParams
			}
		});
	},

	/**
	 * @description 弹窗打开实时视频
	 *
	 * @param {Array} arr
	 * @param {any} otherParam
	 * // 标准参数 ["channelId1", "channelId2" ]
	 * // 兼容qt参数，传入对象{array: [{channelId： "channelId1"}, {channelId： "channelId2"}]}
	 */
	openVideo(arr: Array<String>, otherParam) {
		const config = this.config;
		const { userCode, loginIp } = config;
		let params = {};
		if (!this.isConnectSuccessQt){
			// 未安装客户端且重连失败，提示弹窗信息
			if (this.hasFinishFirstConnectProcess) {
				this.dispatchEvent('showNoDssModalTip', true);
				return Promise.reject('未安装客户端且重连失败');
			}else {
					setTimeout(() => {
						this.uninstallTip();
					}, 2000);
			}
		}
		// 连接成功之后，比对版本，需要升级提示升级
		this.isConnectSuccessQt && this.compareVersion();
		if (Array.isArray(arr)) {
			let _arr = arr.map(i => {
				return { channelId: i };
			});
			params = { array: _arr };
		} else { // 兼容之前的代码，之前根据客户端协议传递，为未处理格式的对象
			params = arr;
		}
		Object.assign(params, otherParam);
		return this.postMessage('openVideo', {
			loginIp,
			userCode,
			params: params
		});
	},

	/**
	 * @description 弹窗打开录像
	 *
	 * @param {any} arr
	 * @param {any} otherParam
	 */
	openRecord(arr: Array<Record>, otherParam) {
		const config = this.config;
		const { userCode, loginIp } = config;
		let params = {};
		if (!this.isConnectSuccessQt){
			// 未安装客户端且重连失败，提示弹窗信息
			if (this.hasFinishFirstConnectProcess) {
				this.dispatchEvent('showNoDssModalTip', true);
				return Promise.reject('未安装客户端且重连失败');
			}else {
					setTimeout(() => {
						this.uninstallTip();
					}, 2000);
			}
		}
		// 连接成功之后，版本不一致比较
		this.isConnectSuccessQt && this.compareVersion();
		if (Array.isArray(arr)) {
			params = { array: arr };
		} else { // 兼容之前的代码，之前根据客户端协议传递，为未处理格式的对象
			params = arr;
		}
		Object.assign(params, otherParam);
		return this.postMessage('openRecord', {
			loginIp,
			userCode,
			params: params
		});
	},

	/**
	 * @description 打开混合弹窗
	 * @params {String} menuCode 菜单code
	 * @params {Array} 根据输入参数展示对应按钮。0:以图搜图，1：上传工作台，2：上传视图作战
	 * @params {Array} array 客户端通道和播放时间
	 *  [{
			"channelId": "AcqRGNrqA1B15042F6AMEO", // 通道Id
			"beginTime": "2021-01-05 13:57:00", // 回放开始时间
			"endTime": "2021-01-05 14:26:59" // 回放结束时间
		}] 
	 * @param {any} otherParam
	 */
	openMixedModeDialog(menuCode, array, toolBarFucList = [0, 1, 2], otherParam) {
		const config = this.config;
		const { userCode, loginIp } = config;
		let params = {
			destinationCode: menuCode,
			toolBarFucList: toolBarFucList,
			array: array
		}
		if (!this.isConnectSuccessQt){
			// 未安装客户端且重连失败，提示弹窗信息
			if (this.hasFinishFirstConnectProcess) {
				this.dispatchEvent('showNoDssModalTip', true);
				return Promise.reject('未安装客户端且重连失败');
			}else {
					setTimeout(() => {
						this.uninstallTip();
					}, 2000);
			}
		}
		// 连接成功之后，版本不一致比较
		this.isConnectSuccessQt && this.compareVersion();
		Object.assign(params, otherParam);
		return this.postMessage('openMixedModeDialog', {
			loginIp,
			userCode,
			params: params
		});
	},

	/**
	 * @description 图片增强
	 *
	 * @param {any} url
	 * @param {any} value
	 * @param {number} [type=1] [[1, 去偏色], [2, 去雾], [3, 夜增强], [4, 降噪]]
	 */
	PictureEnhance(url: any, value: any, type = 1) {
		const config = this.config;
		const { userCode, loginIp } = config;
		const params = {
			loginIp,
			userCode,
			params: {
				array: [{ type: `${type}`, degree: `${value}`, url }]
			}
		};
		return this.postMessage('PictureEnhance', params);
	},

	/**
	 * @description 浏览器聚焦
	 */
	webOnfocus() {
		const config = this.config;
		const { loginIp, userCode } = config;
		this.postMessage('webOnfocus', {
			loginIp,
			userCode
		});
	},
};
