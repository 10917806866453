// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/no-client.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wsvue .no-client[data-v-09436a6a]{width:100%;height:100%;-webkit-box-orient:horizontal;-ms-flex-direction:row;flex-direction:row;position:absolute;top:0;bottom:0;left:0;right:0;z-index:10}.wsvue .no-client[data-v-09436a6a],.wsvue .no-client .anim[data-v-09436a6a]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-direction:normal;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.wsvue .no-client .anim[data-v-09436a6a]{width:600px;height:400px;text-align:center;-webkit-box-orient:vertical;-ms-flex-direction:column;flex-direction:column}.wsvue .no-client .anim .anim-bg[data-v-09436a6a]{margin-bottom:20px;width:254px;height:212px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat}.wsvue .no-client i[data-v-09436a6a]{font-style:normal;color:var(--primary);cursor:pointer}.wsvue .client-loading[data-v-09436a6a]{width:100%;height:100%;z-index:10;position:relative}.wsvue .client-loading[data-v-09436a6a],.wsvue .client-loading .anim[data-v-09436a6a]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.wsvue .client-loading .anim[data-v-09436a6a]{width:600px;height:400px;text-align:center}.wsvue .client-loading .refresh-btn[data-v-09436a6a]{position:absolute;top:50%;margin-top:.5rem;cursor:pointer;z-index:999}.wsvue .client-loading[data-v-09436a6a] .ivu-spin-custom-dot-custom{margin:auto}", ""]);
// Exports
module.exports = exports;
