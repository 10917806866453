/**
 * @description 处理客户端返回数据的数据格式
 * @author 178730 <jin_cong2@dahuatech.com>
 * @date 2021-04-27
 */

export default {
    loginState,
    getVersionResult,
    // goToSearchPic,
    createCtrlResult
}

function loginState(data) {
    return data.params.loginResult === 0;
}

function getVersionResult(data) {
    return data.params.version + '';
}

// function goToSearchPic(data) {
//     return data;
// }

function createCtrlResult(data) {
    return data.params.array;
}