export default {
    // 透传方法，参数不做任何处理
    transparent(method: String, params: any, needTip = false) {
        const config = this.config;
        const { loginIp, userCode } = config;
        if (needTip && !this.isConnectSuccessQt) {
            // 未安装客户端且重连失败，提示弹窗信息
            if (this.hasFinishFirstConnectProcess) {
                this.dispatchEvent("showNoDssModalTip", true);
                // 返回完成，不返回reject
                return Promise.resolve();
            } else {
                setTimeout(() => {
                    this.uninstallTip();
                }, 2000);
            }
        }
        // 连接成功之后，版本不一致比较
        needTip && this.isConnectSuccessQt && this.compareVersion();
        return this.postMessage(method, {
            loginIp,
            userCode,
            params: params
        });
    }
};
