/**
 * @description 处理浏览器位置和DOM对象方法
 * @author 178730 <jin_cong2@dahuatech.com>
 * @date 2021-04-27
 */

/**
 * @description 检测浏览器缩放比例
 */
export function detectZoom() {
    var ratio = 0,
        screen = window.screen,
        ua = navigator.userAgent.toLowerCase();

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf("msie")) {
        if (screen["deviceXDPI"] && screen["logicalXDPI"]) {
            ratio = screen["deviceXDPI"] / screen["logicalXDPI"];
        }
    } else if (
        window.outerWidth !== undefined &&
        window.innerWidth !== undefined
    ) {
        ratio = window.outerWidth / window.innerWidth;
    }

    if (ratio) {
        ratio = Math.round(ratio * 100);
    }
    return ratio;
}

/**
 * @description 检测浏览器是否有Y轴滚动条
 */
export function hasScrollbarY() {
    return (
        getDocument().body.scrollHeight >
        (window.innerHeight || getDocument().documentElement.clientHeight)
    );
}

/**
 * @description 检测浏览器是否有X轴滚动条
 */
export function hasScrollbarX() {
    return (
        getDocument().body.scrollWidth >
        (window.innerWidth || getDocument().documentElement.clientWidth)
    );
}

/**
 * @description 获取浏览器滚动条宽度
 */
export function getScrollbarWidth() {
    var scrollDiv = getDocument().createElement("div");
    scrollDiv.style.cssText =
        "width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;";
    getDocument().body.appendChild(scrollDiv);
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    getDocument().body.removeChild(scrollDiv);

    return scrollbarWidth;
}

/**
 * @description 获取当前浏览器缩放和滚动条信息
 */
export function getScrollInfo() {
    let ratio = detectZoom();
    let scrollX = window.pageXOffset;
    let scrollY = window.pageYOffset;
    var hasscrollbary = hasScrollbarY();
    var hasscrollbarx = hasScrollbarX();
    var scrollbarWidth = getScrollbarWidth();
    let scrollXH = hasscrollbarx ? scrollbarWidth : 0;
    let scrollYW = hasscrollbary ? scrollbarWidth : 0;
    return { ratio, scrollX, scrollY, scrollXH, scrollYW };
}

/**
 * @description 根据domId检测Dom是否有宽和高的属性
 * @param {Obeject} domId 元素ID
 */
export function checkDomExit(domId, log = true) {
    let defaultDomInfo = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    };
    const domTarget = getDocument().getElementById(domId);
    if (!domTarget) {
        log &&
            console.log(
                `ws-basic：checkDomExit function call，can not find dom by id ${domId}`
            );
        return defaultDomInfo;
    }
    const domInfo = domTarget.getClientRects() && domTarget.getClientRects()[0];
    if (domInfo.x == undefined || domInfo.y == undefined) {
        domInfo.x = domInfo.left;
        domInfo.y = domInfo.top;
    }
    if (!domInfo) {
        // 若找不到DOM元素，报错
        log &&
            console.warn(
                `ws-basic：checkDomExit function call，can not find domInfo by id ${domId}, please check dom exited`
            );
        return defaultDomInfo;
    }
    return domInfo;
}

/**
 * @description 判断当前是否在iframe之中
 */
export function isIframe() {
    return self.frameElement && self.frameElement.tagName == "IFRAME";
}

/**
 * @description 获取当前的document对象，兼容iframe处理
 * 存在iframe，实例在top，目标窗口在子层，需要级联
 */
export function getDocument() {
    let _document = window.document;
    return _document;
}

/**
 * @description 通过元素ID获取DOM对象
 * @param {Obeject} domId 元素ID
 * @param {Obeject} iframeToTopPosition
 * @param {Number} iframeToTopPosition.x iframe窗口相对顶层窗口的横向位置
 * @param {Number} iframeToTopPosition.y iframe窗口相对顶层窗口的纵向位置
 *
 */
export function getDomById(domId, iframeToTopPosition) {
    console.log("ws-basic：getDomById start,param domId:", domId);
    const domInfo = checkDomExit(domId);
    const width = domInfo.width;
    const height = domInfo.height;
    let x = domInfo.x;
    let y = domInfo.y;
    // 手动设置iframe相对顶层窗口的位置
    if (iframeToTopPosition && top != window) {
        x += iframeToTopPosition.x || 0;
        y += iframeToTopPosition.y || 0;
    }
    // 当前页面被集成在iframe子窗口时
    else if (isIframe()) {
        console.log("ws-basic：getDomById function call,dom in iframe");
        let iframeRect = self.frameElement.getClientRects();
        if (iframeRect && iframeRect[0]) {
            x += iframeRect[0].x;
            y += iframeRect[0].y;
        }
    }
    return {
        width,
        height,
        x,
        y
    };
}

export default {
    detectZoom,
    hasScrollbarY,
    hasScrollbarX,
    getScrollbarWidth,
    checkDomExit,
    getDocument,
    getDomById
};
