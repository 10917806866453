import account from './account';
import control from './control';
import transparent from './transparent';
// import basic from './basic';

export default {
	account,
	control,
	transparent
	// basic
};
