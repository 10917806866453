import wsvue from "../uiComponents/wsvue.vue";
import noclient from "../uiComponents/noClient.vue";
import DHWs from "../../lib/DHWs";

// 以数组的结构保存组件，便于遍历
const components = [wsvue];

// 检测客户端版本
// let detectDssVersion = true;

// 定义 install 方法
const install = function(Vue, options) {
  if (Vue.prototype.$ws || typeof window.QWebChannel !== "undefined") return;
  console.log("ws-vue:start install dss-tool", options);
  const DHWsInstance = DHWs.getInstance(options); // 引用连接客户端功能，创建ws对象

  // 底部下载提示动态实例化
  const Instance = new Vue({
    data: {
      isUpdate: false
    },
    render(h) {
      return h(noclient, {
        props: { show: this.show, isUpdate: this.isUpdate }
      });
    }
  });
  const component = Instance.$mount();
  document.body.appendChild(component.$el);
  //显示底部下载提示方法挂载到实例对象上
  DHWsInstance.showNoClientTip = function() {
    console.log("showNoClientTip 功能已废除");
  };
  //隐藏底部下载提示方法挂载到实例对像上
  DHWsInstance.hideNoClientTip = function() {
    console.log("hideNoClientTip 功能已废除");
  };
  if (!DHWsInstance.showUpdateClientTip) {
    //显示底部更新提示方法挂载到实例对象上
    DHWsInstance.showUpdateClientTip = function() {
      console.log("showUpdateClientTip start");
      try {
        // 在iframe中且顶层有实例
        if (window.top != window.self && window.top["$wsInstance"]) {
          if (window.top["$wsInstance"] !== DHWsInstance) {
            Instance.isUpdate = false;
            console.log("当前位iframe窗口，采用多实例模式，打开顶层更新提示");
            window.top["$wsInstance"].showUpdateClientTip();
          } else {
            console.log(
              "当前位iframe窗口，采用的是单实例模式，直接打开更新提示"
            );
            Instance.isUpdate = true;
          }
        } else {
          // 在顶层中时需要将iframe中的提示都隐藏
          if (window.top == window.self) {
            let iframes =
              window.top.document.getElementsByTagName("iframe") || [];
            iframes.forEach(iframe => {
              if (
                iframe.contentWindow["$wsInstance"] &&
                iframe.contentWindow["$wsInstance"] !== DHWsInstance
              ) {
                iframe.contentWindow["$wsInstance"].hideUpdateClientTip();
              }
            });
          }
          console.log("当前位top窗口，直接打开更新提示");
          Instance.isUpdate = true;
        }
      } catch (error) {
        console.log("暂不支持跨域使用", error);
      }
    };
  }
  if (!DHWsInstance.hideUpdateClientTip) {
    //隐藏底部更新提示方法挂载到实例对像上
    DHWsInstance.hideUpdateClientTip = function() {
      Instance.isUpdate = false;
    };
  }
  if (!DHWsInstance.showNoResourceTip) {
    // 提升暂无视频插件资源
    DHWsInstance.showNoResourceTip = function() {
      Vue.prototype.$Message &&
        Vue.prototype.$Message.error("视频插件下载资源不存在");
    };
  }

  Vue.prototype.$ws = DHWsInstance; // 挂在到Vue
  // 监听展示无客户端提示弹窗消息
  isShowNoDssModalTip(DHWsInstance, Vue);
  // 监听更新版本弹窗消息
  // updateDssModal(DHWsInstance, Vue);
  // 监听更新版本悬浮提示消息
  updateDss(DHWsInstance, Vue);

  // 遍历并注册全局组件
  components.map(component => {
    Vue.component(component.name, component);
  });
};

/**
 * @description 监听展示无客户端提示弹窗消息
 */
function isShowNoDssModalTip(DHWsInstance, Vue) {
  if (!DHWsInstance.hasAddShowNoDssModalTip) {
    DHWsInstance.addEventListener("showNoDssModalTip", () => {
      Vue.prototype.$Modal.confirm({
        title: "视频插件下载提示",
        zIndex: 999999,
        content:
          "检测到您未安装视频插件，将无法使用相关功能，请点击确定下载安装后使用",
        onOk: () => {
          DHWsInstance.downloadClient();
        },
        onCancel: () => {
          console.log("ws-vue:关闭插件下载提示弹窗");
        }
      });
    });
    DHWsInstance.hasAddShowNoDssModalTip = true;
  }
}

/**
 * @description 监听更新版本悬浮提示消息
 */
function updateDss(DHWsInstance) {
  DHWsInstance.addEventListener("updateDss", () => {
    DHWsInstance.showUpdateClientTip();
  });
}

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  // 导出的对象必须具备一个 install 方法
  install,
  // 组件列表
  ...components
};
