<template>
  <div
    class="no-dssclient-tips"
    v-if="isUpdate"
    style=" position: fixed;bottom: 0;width: 100%;padding: 0.15rem;z-index: 999999;background: var(--bg);text-align: center;border-top: 1px solid var(--split);"
  >
    <!-- 新客户端版本 -->
    <div>
      <span>系统检测到新版本视频插件，是否立即更新?</span>
      <span style="margin-left:20px;">
        点击
        <a style="color:var(--primary)" @click="dss_downloadClient">下载安装</a>
      </span>
      <span style="margin-left:5px;" @click="noTip">
        <Button>稍后再说({{ count }}秒)</Button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "noclient",
  props: {
    // 是否为升级轻客户端提示
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      count: 0,
      clearId: null
    };
  },
  methods: {
    noTip() {
      this.$ws.hideUpdateClientTip();
    },
    dss_downloadClient() {
      this.$ws.downloadClient();
    }
  },

  watch: {
    isUpdate(val, oldVal) {
      if (val && !oldVal) {
        this.count = 5;
      }
    }
  },
  mounted() {
    if (this.isUpdate) {
      this.count = 5;
    }
    if (this.clearId) {
      clearInterval(this.clearId);
    }
    this.clearId = setInterval(() => {
      if (this.count > 0) {
        this.count = this.count - 1;
      } else {
        if (this.isUpdate) {
          this.noTip();
        }
      }
    }, 1000);
  },
  beforeDestroy() {
    if (this.clearId) {
      clearInterval(this.clearId);
    }
  }
};
</script>
