// 与客户端交互方法列表
import wsActions from './actions/index';
import defaultMessageEvents from './messageEvent/index';
import dealEventData from './utils/dealMessageEventData';
// const ReWebSocket = require('reconnecting-websocket');
// websocket重连
import ReWebSocket from '../node_modules/reconnecting-websocket/dist/reconnecting-websocket-cjs';
// const Bowser = require('bowser');
// 浏览器信息获取
import Bowser from "../node_modules/bowser/es5";
const browser = Bowser.getParser(window.navigator.userAgent);
//版本信息
const DHWs_version = '1.1.5';
console.log(`ws-basic:DHWs version:${DHWs_version}`);

interface callbacks {
	// 连接客户端状态回调
	connectResult: any,
	// 登录客户端状态回调
	loginResult: any
}


export default class Ws {
	[x: string]: any;
	// 连接地址
	static url: string = '';
	// 密码加密公钥
	static publicKey = '';
	// 是否连接客户端
	private isConnectSuccessQt: Boolean = false;
	// 是否登录客户端
	private isLoginSuccess: Boolean = false;
	// 回调函数
	private callback: callbacks;
	// 唯一实例
	private static _instance: any = null;
	// 外部注册客户端消息处理
	messageEvents: Object = {};
	// 当前连接的webSocket
	webSocket: any;
	// 最大重连数
	reConnectCount: number = 1;
	// 连接失败次数
	connectFailCount: number = 0;
	//是否完成重连流程
	hasFinishFirstConnectProcess:Boolean = false;
	// 连接完成标识
	connectEnd: Boolean = true;
	// 连接客户端开始时间
	connectStartTime: number = 0;
	// 连接超时时间
	connectTimeOut: number = 1 * 1000;
	// 登录完成标识
	loginEnd: Boolean = true;
	// 登录客户端开始时间
	loginStartTime: number = 0;
	// 登录超时时间
	loginTimeOut: number = 60 * 1000;
	// 最大重登数
	reLoginCount: number = 0;
	// 连接失败次数
	loginFailCount: number = 0;
	// 当前websocket登录配置
	config: Object = {
		browser: {}
	};
	// 登陆IP
	loginIp: string;
	// 登陆端口
	loginPort: string;
	// 登录用户名
	userName: string;
	// 登录密码，密码与token二选一
	userPwd: string;
	// 登录token，密码与token二选一
	token: string;
	// 用户标识符
	userCode: Number = 0;
	// 浏览器tab页中的titleID（针对国产化所需）
	titleID: string;
	// 是否检测客户端版本
	detectDssVersion: Boolean = true;
	// 当前客户端版本
	currentDssVersion: string = '';
	// 获取当前版本结束标志
	getVersionEnd: Boolean = true;
	// 线上客户端版本
	onlineDssVersion: string = '';
	// 线上客户顿版本获取地址后缀，32系统文件名添加32
	onlineDssVersionAfterfix: string = '/data/VSL/DSSEnterpriseClient/LightWeightVersion.txt';
	// 线上客户顿获取地址后缀，32系统文件名添加32
	onlineDssAfterfix: string = '/data/VSL/DSSEnterpriseClient/DSS_LightWeight_Client.zip';
	// 是否一直保持重新连接
	isKeepConnect: Boolean = false;
	// 创建的控件ID列表
	ids: Array<string> = [];
	// 创建的控件列表
	ctrls: Array<string> = [];
	// 心跳
	heartBeatTimer: any = null;
	// 监听的添加式方法表
	handlers: Object = {};
	// 监听的覆盖式方发表
	listerns: any;
	// 关闭消息打印
	isCloseMsgConsole: Boolean = true;
	//是否手动设置窗口在相对顶层窗口左上角位置
	manualSetIframePosition:Boolean = false;
	// 手动设置窗口在相对顶层窗口左上角位置
	iframeToTopPosition:Object = {};
	constructor({
		url = 'ws://localhost:1234',
		publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDbEpPpxpLJft4W9YZj8bRh2bYYZshBEsKOlxgyn11rlEyTasjBSZRV9aj33tvQ2T55izH0fWl+dL/dLZChawFrlGDcH8JuWge2xYMgII9mggcYa0UiQ7pLXJ9ivXZ/cOY3HzrRQdR7dGTSNn3Z0Ctbns6mLgvlA2r3qMNs/8wHBwIDAQAB',
		reConnectCount = 1,
		connectTimeOut = 1 * 1000,
		reLoginCount = 0,
		loginIp = location.hostname,
		loginPort = location.port,
		userName = '',
		userPwd = '',
		token = '',
		callback = {
			connectResult: null,
			loginResult: null
		},
		isKeepConnect = false,
		isCloseMsgConsole=true,
		detectDssVersion = true,
		messageEvents = {},
		manualSetIframePosition=false,
		iframeToTopPosition ={}
	}: {
		url: string,
		publicKey: string,
		reConnectCount: number,
		connectTimeOut: number,
		reLoginCount: number,
		loginIp: string,
		loginPort: string,
		userName: string,
		userPwd: string,
		token: string,
		callback: callbacks,
		isKeepConnect: boolean,
		isCloseMsgConsole: boolean,
		detectDssVersion: boolean,
		actions: Object,
		messageEvents: Object,
		manualSetIframePosition:boolean,
		iframeToTopPosition:Object
	}) {
		// if (/https/.test(location.protocol)) {
		// 	url = `wss:${url}`;
		// } else {
		// 	url = `ws:${url}`;
		// }
		// url = `ws://localhost:1234`;

		this.url = url;
		this.userCode = new Date().valueOf();
		this.webSocket = null;
		this.titleID = new Date().valueOf().toString();
		this.reConnectCount = reConnectCount;
		this.connectTimeOut = connectTimeOut;
		this.reLoginCount = reLoginCount;
		this.publicKey = publicKey;
		this.loginIp = loginIp;
		this.loginPort = loginPort;
		this.userName = userName;
		this.userPwd = userPwd;
		this.token = token;
		this.callback = callback;
		this.isKeepConnect = isKeepConnect;
		this.detectDssVersion = true;
		this.messageEvents = messageEvents || {};
		this.isCloseMsgConsole = isCloseMsgConsole;
		// 为兼容历史版本检查参数传入,新版本已通过该参数进行控制版本
		console.log(detectDssVersion);
		// 基础操作所需功能或属性
		this.ids = [];
		this.ctrls = [];

		// 是否连接客户端
		this.isConnectSuccessQt = false;
		// 是否登陆客户端
		this.isLoginSuccess = false;
		this.heartBeatTimer = null;

		// 用户注册监听事件表
		this.handlers = {};

		// 用户注册监听事件表
		this.listerns = new Map();

		//是否手动设置窗口在相对顶层窗口左上角位置
		this.manualSetIframePosition = manualSetIframePosition;

		// 手动设置窗口在相对顶层窗口左上角位置
		this.iframeToTopPosition = iframeToTopPosition;


		// 获取与客户端交互方法列表
		const defaultActions = [];
		Object.keys(wsActions).forEach(item => {
			if (hasKey(wsActions, item)) {
				defaultActions.push(wsActions[item]);
			}
		});
		// 与客户端通讯功能注册
		usePlugin(defaultActions);

		// 处理客户端消息 合并以内置方法为主，客户自定义事件与内置方法重复，则抛弃
		this.messageEvents = Object.assign(this.messageEvents, defaultMessageEvents);
		Object.keys(this.messageEvents).forEach(item => {
			this.addEventListener(item, this.messageEvents[item].bind(this));
		});


		// 初始化配置参数
		this.initConfig();
		//预先获取服务器上的视频插件版本
		this.getOnlineDssVersion()
		// 连接客户端
		this.connectQt();

		this.setDocumentTitle();

		// 登录
		// this.detectConnectQt().then((res: Boolean) => {
		// 	if(res) {
		// 		// 登陆客户端
		// 		this.loginClient();
		// 	}
		// });
	}
	/**
	 * @description 国产化需要设置title作为网页唯一标识，非国产化不用
	 */
	setDocumentTitle() {
		// 非windows环境下才拼接 titleID 到title中
		if (!(/win32|win64|wow32|wow64/.test(navigator.userAgent.toLowerCase()))) {
			try {
				// 判断是否在iframe中
				if (window.top == window.self) {
					// 在顶层中，则直接拼接 titleID 到title中
					document.title += "-" + this.titleID;
				} else {
					// 同源时顶层title未修改过则修改顶层title
					if (!window.top['hasModifyTitle']) {
						window.top.document.title += "-" + this.titleID;
						window.top['hasModifyTitle'] = true;
					}
				} 
			} catch (error) {
				console.warn('暂不支持跨域使用');
				// 非同源时给顶层发消息，让其修改title
				document.referrer && top.postMessage(this.titleID, document.referrer)
			}
		}
	}
	/**
	 * @description 获得实例对象
	 */
	public static getInstance(options: any): Ws {
		let isMultiInstance = (options && options.isMultiInstance) ? options.isMultiInstance : false;
		// 属性表示当前window已经修改过title
		window['hasModifyTitle'] = true;
		try {
			// 多例模式时直接创建新的实例
			if (isMultiInstance)  {
				this._instance = new Ws(options || {});
				// 为window赋值，从而可以在iframe中访问顶层

				// 自己窗口创建的实例挂载到wsInstance，用于区别从其他窗口继承实例，安装升级提示中用到
				window['$wsInstance'] = this._instance;

				// 重新更名保持和历史版本的全局变量一致；wsInstance继续使用
				window['$ws'] = this._instance;
			} else {
				if (!top['$ws']) {
					if (!this._instance) {
						this._instance = new Ws(options || {});
						// top['$ws'] = this._instance;
						window['$ws'] = this._instance;

						// 自己窗口创建的实例挂载到wsInstance，用于区别从其他窗口继承实例，安装升级提示中用到
						window['$wsInstance'] = this._instance;
					}
				} else {
					this._instance = top['$ws'];
				}
			}
		}
		catch (e) {
			if (!this._instance) {
				this._instance = new Ws(options || {});
				window['$ws'] = this._instance;
				// 自己窗口创建的实例挂载到wsInstance，用于区别从其他窗口继承实例，安装升级提示中用到
				window['$wsInstance'] = this._instance;
			}
		}

		return this._instance;
	}
	/**
	 * @description 用户注册监听事件，覆盖式监听
	 * @params {String} eventType 事件名称
	 * @params {any} callback 回调函数
	 */
	on(eventType, callback) {
		this.listerns.set(eventType, callback);
	}
	/**
	 * @description 用户取消监听事件，覆盖式监听
	 * @params {String} eventType 事件名称
	 */
	off(eventType) {
		delete this.listerns[eventType];
	}
	/**
	 * @description 用户注册监听事件，添加式监听
	 * @params {String} eventType 事件名称
	 * @params {any} handler 回调函数
	 */
	addEventListener(eventType: string, handler: Function) {
		// 首先判断handlers内有没有type事件容器，没有则创建一个新数组容器
		if (!(eventType in this.handlers)) {
			this.handlers[eventType] = []
		}
		// 将事件存入
		this.handlers[eventType].push(handler)
	}
	/**
	 * @description 添加式监听，触发事件
	 * @params {String} eventType 事件名称
	 * @params {any} params 函数参数
	 */
	dispatchEvent(eventType: string, ...params) {
		// 若没有注册该事件则抛出错误
		if (!(eventType in this.handlers)) {
			return new Error('未注册该事件')
		}
		// 便利触发
		this.handlers[eventType].forEach(handler => {
			handler(...params)
		})
	}
	/**
	 * @description 用户取消监听事件
	 * @params {String} eventType 事件名称
	 */
	removeEventListener(eventType: string, handler) {
		// 无效事件抛出
		if (!(eventType in this.handlers)) {
			return new Error('无效事件')
		}
		if (!handler) {
			// 直接移除事件
			delete this.handlers[eventType]
		} else {
			const idx = this.handlers[eventType].findIndex(ele => ele === handler)
			// 抛出异常事件
			if (idx === -1) {
				return new Error('无该绑定事件')
			}
			// 移除事件
			this.handlers[eventType].splice(idx, 1)
			if (this.handlers[eventType].length === 0) {
				delete this.handlers[eventType]
			}
		}
	}
	/**
	 * @description 未安装提示
	 */
	uninstallTip (){
		// 未安装客户端且重连失败，提示弹窗信息
		if (!this.isConnectSuccessQt) {
			this.dispatchEvent("showNoDssModalTip", true);	
		}
	}
	/**
	 * @description 发送消息给客户端
	 * @params {String} method 事件名称
	 * @params {Object} data 传输消息的数据内容
	 */
	postMessage(method: any, data: { method: any; }) {
		if (method !== 'heartbeat') {
			console.log('ws-basic:postMessage start:', method, data);
		}
		const { webSocket } = this;
		data.method = method;
		// 不需要判断登录和连接的方法过滤
		let filterList = ['heartbeat', 'login', 'logout', 'browserInfo', 'getVersion', 'trusteSite'];
		if (filterList.includes(method)) {
			webSocket.send(JSON.stringify(data));
			return Promise.resolve(true);
		}
		
		return new Promise((resolve, reject) => {
			this.keeper().then(res => {
				if (res) {
					webSocket.send(JSON.stringify(data));
					resolve(true);
				} else {
					console.error('ws-basic:postMessage失败，keeper参数异常')
					reject(false);
				}
			}).catch(e => {
				console.error('ws-basic:postMessage 方法调用失败，ws未连接成功')
				reject(false);
			})
		})
	}
	/*
	* 初始化配置
	*/
	initConfig() {
		// token登陆的username通过token截取
		if (this.token && this.token.split('_')[0]) {
			this.userName = this.token.split('_')[0]
		}
		this.config = {
			userName: this.userName,
			userCode: this.userCode,
			loginPort: this.loginPort,
			loginIp: this.loginIp,
			userPwd: this.userPwd,
			token: this.token,
			tokenHeartbeatTime: 1800
		};
		// 浏览器信息
		const browserInfo = {
			name: '',
			version: '',
			platform: ''
		};
		browserInfo.name = browser.getBrowserName().toLowerCase();
		browserInfo.version = browser.getBrowser().version.toLowerCase();
		browserInfo.platform =
			browser._ua.indexOf('Win64') >= 0 || browser._ua.indexOf('Wow64') >= 0 
			? 'win64' : (browser._ua.indexOf('Win32') >= 0 || browser._ua.indexOf('Wow32') >= 0 ? 'win32' : 'notWin');
		this.config['browser'] = browserInfo;
	}
	/**
	 * @description 连接客户端
	 */
	connectQt() {
		this.connectFailCount = 0;
		this.connectEnd = false;
		this.connectStartTime = Date.now();

		// 连接客户端
		this.webSocket = new ReWebSocket(this.url, '', {
			maxRetries: this.reConnectCount,
			connectionTimeout: this.connectTimeOut
		});
		// 取消订阅的事件
		this.removeEvents();
		// 订阅事件
		this.addEvents();

		return this.detectConnectQt();
	}
	/**
	 * @description 获取线上和线下客户端版本
	 */
	getDSSVersion() {
		this.getVersion();
		this.getOnlineDssVersion();
	}
	/**
	 * @description 获取线上客户端版本
	 * 降低轻客户端版本升级提醒频繁度，取消版本比对方法调用 @2023-1-31 by 周伟
	 */
	getOnlineDssVersion() {

		// 待解决跨域问题
		let _this = this;
		let platform = this.config['browser'].platform;
		let versionUrl = this.onlineDssVersionAfterfix;
		
		//如果缓存中的版本存在直接以版本为准
		if(_this.onlineDssVersion) {
			return;
		}
		
		// 非windows下时需要带上架构信息读取版本
		if (platform !== 'notWin') {
			if (platform === 'win32') {
				versionUrl = versionUrl.replace('.txt', '32.txt');
			}
		} else {
			let agent = navigator.userAgent.toLowerCase();
			if(agent.indexOf("aarch64") >= 0){
				versionUrl = '/data/VSL/DSSEnterpriseClient/aarch64/LightWeightVersion.txt';		
			}else if(agent.indexOf("mips64") >= 0){
				versionUrl = '/data/VSL/DSSEnterpriseClient/mips64/LightWeightVersion.txt';
			}else if(agent.indexOf("x86_64") >= 0){
				versionUrl = '/data/VSL/DSSEnterpriseClient/x86_64/LightWeightVersion.txt';
			}
		}
		var request = new XMLHttpRequest();
		request.open("get", versionUrl);/*设置请求方法与路径*/
		request.send(null);/*不发送数据到服务器*/
		request.onload = function () {/*XHR对象获取到返回信息后执行*/
			if (request.status == 200) {/*返回状态为200，即为数据获取成功*/
				_this.onlineDssVersion = request.response.replace('ClientVersion = ', '')
			}
		}
	}
	/**
	 * @description 检测连接客户端状态
	 */
	detectConnectQt() {
		let _this = this;
		return new Promise((resolve, reject) => {
			if (!this.connectEnd) { // 连接中
				let _interval = setInterval(() => {
					if (_this.connectEnd) {
						clearInterval(_interval);
						resolve(_this.isConnectSuccessQt);
					}
				}, 50)
			} else {
				resolve(_this.isConnectSuccessQt);
			}
		})
	}
	/**
	 * @description 检测登录客户端状态
	 */
	detectLoginClient() {
		let _this = this;
		return new Promise((resolve, reject) => {
			if (!this.loginEnd) { // 登录中
				let _interval = setInterval(() => {
					if (_this.loginEnd) {
						clearInterval(_interval);
						resolve(_this.isLoginSuccess);
					}
				}, 50)
			} else {
				resolve(_this.isLoginSuccess);
			}
		})
	}
	/**
	 * @description 连接状态守护
	 */
	keepConnect() {
		return new Promise((resolve, reject) => {
			// 监测连接状态
			this.detectConnectQt().then((connectStatus: Boolean) => {
				// 连接正常
				if (connectStatus) {
					resolve(true);
				} else {
					// 连接状态失效，重新连接
					this.connectQt().then((ReconnectStatus: Boolean) => {
						// 重连成功
						if (ReconnectStatus) {
							resolve(true);
						} else {
							console.log(`ws-basic:keepConnect function call ,reConnect-fail`);
							reject(false);
						}
					})
				}
			});
		})
	}
	/**
	 * @description 连接和登录状态守护
	 */
	keeper() {
		return new Promise((resolve, reject) => {
			// 监测连接状态
			this.detectConnectQt().then((connectStatus: Boolean) => {
				// 连接正常
				if (connectStatus) {
					this.detectLoginClient().then((loginStatus: Boolean) => {
						// 登录正常
						if (loginStatus) {
							resolve(true);
						} else {
							// 登录异常
							// 登录
							this.loginClient()
							// 登录后的状态监测
							this.detectLoginClient().then((reLoginStatus: Boolean) => {
								if (reLoginStatus) {
									resolve(true);
								} else {
									console.log(`ws-basic:keeper function call,reLogin-fail`);
									reject(false);
								}
							})
						}
					})
				} else {
					// 连接状态失效，重新连接
					this.connectQt().then((ReconnectStatus: Boolean) => {
						// 连接成功
						if (ReconnectStatus) {
							// 登录
							console.log(`ws-basic:keeper function call, reConnect success,start reLogin`);
							this.loginClient()
							// 登录状态监测
							this.detectLoginClient().then((loginStatus: Boolean) => {
								if (loginStatus) {
									resolve(true);
								} else {
									console.log(`ws-basic:keeper function call,reLogin fail`);
									reject(false);
								}
							})
						} else {
							console.log(`ws-basic:keeper function call, reConnect fail`);
							reject(false);
						}
					})
				}
			});
		})
	}
	/**
	 * @description 获取当前客户端版本
	 */
	getLocalDssVersion() {
		let _this = this;
		this.getVersion();
		console.log(`ws-basic:getLocalDssVersion start`)
		return new Promise((resolve, reject) => {
			if (!this.getVersionEnd) { // 连接中或者获取中
				let _interval = setInterval(() => {
					if (_this.getVersionEnd) {
						clearInterval(_interval);
						resolve(_this.currentDssVersion);
					}
				}, 50)
			} else {
				resolve(_this.currentDssVersion);
			}
		})
	}
	/**
	 * @description 比较客户端版本
	 */
	compareVersion() {
		console.log(`ws-basic:getLocalDssVersion start,currentDssVersion:${this.currentDssVersion} , onlineDssVersion:${this.onlineDssVersion}`);

		if (this.currentDssVersion && this.onlineDssVersion) {
			if(Number(this.onlineDssVersion) > Number(this.currentDssVersion)) {
				this.dispatchEvent('updateDss');
			}
		}else{
			let clearId = setInterval(()=>{
				if(this.currentDssVersion && this.onlineDssVersion){
					clearInterval(clearId);
					if(Number(this.onlineDssVersion) > Number(this.currentDssVersion)) {
						this.dispatchEvent('updateDss');
					}
				}
			},120)
		}
		
	}
	/**
	 * @description 下载客户端
	 */
	downloadClient(origin) {

		// 视频插件资源不存在时，提升下载资源不存在
		if(!this.onlineDssVersion && this.showNoResourceTip){
			this.showNoResourceTip();
			return false;
		}
		var agent = navigator.userAgent.toLowerCase();
		let type = '32';
		
		let downUrl = '/data/VSL/DSSEnterpriseClient/DSS_LightWeight_Client';


		
		if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
			type = '32';
		}else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
			type = '64';
		}else if(agent.indexOf("aarch64") >= 0){
			type = 'aarch64';
			downUrl = '/data/VSL/DSSEnterpriseClient/aarch64/DSS_LightWeight_Client';		
		}else if(agent.indexOf("mips64") >= 0){
			type = 'mips64';
			downUrl = '/data/VSL/DSSEnterpriseClient/mips64/DSS_LightWeight_Client';
		}else if(agent.indexOf("x86_64") >= 0){
			type = 'x86_64';			
			downUrl = '/data/VSL/DSSEnterpriseClient/x86_64/DSS_LightWeight_Client';
		}		
		origin ? downUrl = origin + downUrl : downUrl = location.origin + downUrl;
		// type === '32' ? downUrl = downUrl + '32.zip' : downUrl = downUrl + '.zip';
		// window的32位系统则为32.zip结尾，64位系统为.zip结尾，国产化系统则为.tar.gz
		downUrl += type === '32' ? '32.zip' : (type === '64' ? '.zip' : '.tar.gz');

		window.open(downUrl);
	}
	/**
	 * @description 登录客户端
	 * @params {Object} config 登录相关配置
	 */
	login(config: any) {
		console.log('ws-basic:login start');
		this.loginFailCount = 0;

		// 如果登陆时用户名变更，更新userCode
		let { userName, token, verifyCode } = config;

		// token登录时如果verifyCode没传，正常登录，但打印错误，提示需要传入verifyCode
		if(verifyCode === undefined || verifyCode === '') {
			console.warn('轻量化客户端登录接口参数中请传入verifyCode字段，其值为用户编码，如不传则当VSL为vita版本之后，客户端无法接收到VSL推送，会导致如客户端设备树无法实时更新等的问题');
		}

		// token登陆的username通过token截取
		if (!userName && token) {
			userName = token.split('_')[0];
		}
		// 新用户登录时更新插件唯一编码userCode
		if (this.config['userName'] && (userName !== this.config['userName'])) {
			this.userCode = new Date().valueOf();
		}
		// vsl用户编码
		this.userName = userName;
		this.initConfig();

		Object.assign(this.config, config);
		// 登录时增加网页聚焦事件
		(window as any).onfocus = () => this.webOnfocus();
		this.detectConnectQt().then((res: Boolean) => {
			if (res) {
				// 登陆客户端
				this.detectLoginClient().then(res => {
					!res && this.loginClient();
				})
				// 获取线上客户端版本 
				this.detectDssVersion && this.getDSSVersion();
			} else {
				// 未连接客户端
				this.connectQt().then((res1: Boolean) => {
					if (res1) {
						// 登陆客户端
						this.loginClient();
						// 获取线上客户端版本
						this.detectDssVersion && this.getDSSVersion();
					} else {
						throw new Error('连接客户端失败');
					}
				})
			}
		});
	}
	/**
	 * @description 登出客户端
	 */
	logout() {
		// 退出客户端
		this.detectLoginClient().then(res => {
			res && this.logoutClient();
		})
		this.ids = [];
		this.ctrls = [];
		this.isLoginSuccess = false;
		if (typeof this.callback.loginResult === 'function') {
			this.callback.loginResult.call(this, this.isLoginSuccess);
		}
	}
	/**
	 * @description 添加websocket/window监听事件
	 */
	addEvents() {
		const webSocket = this.webSocket;
		webSocket.addEventListener('open', this.onOpen.bind(this));
		webSocket.addEventListener('message', this.onMessage.bind(this));
		webSocket.addEventListener('error', this.onError.bind(this));
		window.addEventListener('resize', this.reLocatedPosition.bind(this));
		window.addEventListener('scroll', this.reLocatedPosition.bind(this));
		window.addEventListener('visibilitychange', this.webVisibilityChange.bind(this));
		window.addEventListener('onunload', this.closeBrowser.bind(this));
	}
	/**
	 * @description 移除websocket/window监听事件
	 */
	removeEvents() {
		const webSocket = this.webSocket;
		webSocket.removeEventListener('open', this.onOpen);
		webSocket.removeEventListener('message', this.onMessage);
		webSocket.removeEventListener('error', this.onError);
		window.removeEventListener('resize', this.reLocatedPosition);
		window.removeEventListener('scroll', this.reLocatedPosition);
		window.removeEventListener('visibilitychange', this.webVisibilityChange);
		window.removeEventListener('onunload', this.closeBrowser);
	}
	/**
	 * @description 连接客户端成功事件
	 */
	onOpen() {
		console.log('ws-basic:onOpen start');
		// chrome浏览器版本大于80，需要添加信任站点，加载http/https跨站点资源
		// if(this.config['browser'].name === 'chrome' && this.config['browser'].version && Number(this.config['browser'].version.split('.')[0]) >= 80) {
		// 	this.trusteSite(location.hostname);
		// }
		this.isConnectSuccessQt = true;
		this.dispatchEvent('connectStateChange', true);
		this.connectEnd = true;
		if (typeof this.callback.connectResult === 'function') {
			this.callback.connectResult.call(this, this.isConnectSuccessQt);
		}
		this.detectDssVersion && this.getDSSVersion();
	}
	/**
	 * @description 接收客户端消息
	 * @params {Object} event 接收客户端的消息数据
	 */
	onMessage(event: { data: string; }) {
		if(!this.isCloseMsgConsole){
			console.log('ws-basic:onMessage start', event);
		}
		try {
			const data = JSON.parse(event.data);
			const { method } = data;
			const callback = this.listerns.get(method);
			// 数据格式处理
			let dealDataRes = dealEventData[method] ? dealEventData[method](data) : data;
			this.dispatchEvent(method, dealDataRes);
			if (method === 'loginState') {
				callback && callback(this.isLoginSuccess);
			} else {
				callback && callback(data);
			}
		} catch (e) {
			// 客户端协议中含有返回的不是Json数据
			if(!this.isCloseMsgConsole){
				console.log('ws-basic:客户端返回的消息不是Json数据', e);
			}
		}
	}
	/**
	 * @description 客户端发生错误事件
	 */
	onError() {
		this.ctrls = [];
		this.ids = [];
		this.connectFailCount++;
		if (this.connectFailCount === this.reConnectCount + 1) {
			clearTimeout(this.heartbeatTimer);
			this.isConnectSuccessQt = false;
			this.hasFinishFirstConnectProcess = true;
			this.dispatchEvent('connectStateChange', false);
			this.connectEnd = true;
			this.isKeepConnect && this.keepConnect();
			if (typeof this.callback.connectResult === 'function') {
				this.callback.connectResult.call(this, this.isConnectSuccessQt);
			}
		}
	}
	/**
	 * @description 判断是否成功连接客户端
	 */
	isOpen() {
		if (!this.webSocket) return false;
		return this.webSocket.readyState === 1;
	}
	/**
	 * @description 心跳事件
	 */
	_heartbeat() {
		this.heartbeat();
		clearTimeout(this.heartbeatTimer);
		this.heartbeatTimer = setTimeout(() => {
			this._heartbeat();
		}, 10000);
	}
	/**
	 * @description 关闭浏览器事件
	 */
	closeBrowser() {
		this.logout();
		// 延迟100ms登出，关闭所有控件
		var timestamp = new Date().getTime();
		while ((new Date().getTime() - timestamp) < 100) { };
	}
}

/**
 * @description 判断对象是否含有某属性
 * @params {Object} obj 对象
 * @params {String} key 属性key
 */
function hasKey<O>(obj: O, key: keyof any): key is keyof O {
	return key in obj
}

/**
 * @description 与客户端通讯功能注册
 * @params {Array} actions 功能列表
 */
function usePlugin(actions: any[]) {
	actions.forEach((plugin) => {
		Object.getOwnPropertyNames(plugin).forEach(prop => {
			Ws.prototype[prop] = plugin[prop];
		});
	});
}
