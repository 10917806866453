<template>
    <div class="wsvue" :id="comDomId">
        <!-- 插件未安装提示 -->
        <div v-if="!hideInstallTip && showInstallTip" class="no-client">
            <div class="anim">
                <div class="anim-bg"></div>
                <div>
                    您未安装视频插件,点击
                    <i @click="downloadDss">下载安装</i>,刷新页面后生效
                </div>
            </div>
        </div>

        <!-- 加载中 -->
        <div
            v-if="!hideLoadingTip && loading && !showInstallTip"
            class="client-loading"
        >
            <div class="anim">
                <Spin size="large"></Spin>
            </div>
            <Button v-if="showBtn" class="refresh-btn" @click="refreshFun()"
                >刷新</Button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "wsvue",
    props: {
        // 控件ID，唯一标识
        ctrlId: {
            type: String,
            default: "ws" + (Math.random() * 10000000000).toFixed(0),
        },

        // 组件销毁时，仅仅隐藏，再次激活时，展示
        keepAlive: {
            type: Boolean,
            default: true,
        },

        // domID，唯一标识
        domId: {
            type: String,
            default: "",
        },

        // 视频类型 1-实时视频， 2-录像回放，3-实时预览功能模块，4-录像回放功能模块， 5-事件规则配置，6、实时人脸 7、事件中心 8、体温检测模块 9、下载中心模块 10、纪委下载刻录模块
        videoType: {
            type: [Number, String],
            default: 1,
        },

        //  以图搜图操作，视图检索菜单码，客户端在相关方法回传,仅在videoType为 3、4,
        destinationCode: {
            type: String,
            default: "",
        },

        //  身份核查菜单码，客户端在相关方法回传,仅在videoType为 4,
        searchPersonCode: {
            type: String,
            default: "",
        },

        // 视频个数，配合videoType为1、2、5、6的时候使用，实时预览和录像回放模块不需要此参数
        splitNum: {
            type: [Number, String],
            default: 1,
        },

        // 是否显示
        visible: {
            type: Boolean,
            default: true,
        },

        // 剪切视频控件的选择器
        cutSelectors: {
            type: Array,
            default: () => [],
        },

        // 实时播放参数 配合videoType为1的时候使用,['123', '333']
        realTimeChannelIds: {
            type: Array,
            default: () => [],
        },

        // 录像播放参数 配合videoType为2的时候使用
        // [
        //     {
        //         beginTime: '2020-04-29 14:02:00',
        //         endTime: '2020-04-30 14:02:00',
        //         channelId: '12312123'
        //     }
        // ]
        recordVideoProperty: {
            type: Array,
            default: () => [],
        },

        // videoType为3、4时使用，控制显示操作图标按钮，0:以图搜图，1：上传工作台，2：上传视图作战
        toolBarFucList: {
            type: Array,
            default: () => [0, 1, 2],
        },

        // 事件规则配置对象 videoType为5时使用，作为事件规则配置的参数

        // algorithmOption.algorithmId  算法编码
        // algorithmOption.channelId 通道id
        // algorithmOption.channelName 通道名称
        // algorithmOption.channelAbilityPO 已配置的规则
        // algorithmOption.videoConfigRuleProperty 规则模板
        algorithmOption: {
            type: Object,
            default: () => {
                return {};
            },
        },
        hideLoadingTip: {
            type: Boolean,
            default: false,
        },
        hideInstallTip: {
            type: Boolean,
            default: false,
        },
        PlaybackContent: {
            type: Object,
            default: null,
        },
        //录像回放图播跳转解析任务时使用的菜单编码并根据该编码对图播功能权限控制,有权限传递菜单码
        taskMenuCode: {
            type: String,
            default: "",
        },
        //视频图播中解析任务是否拥有插队权限,有权限传递菜单码
        cutInLineBtnCode: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            // 缩放比例
            ratio: 100,
            // 控件横坐标位置
            posX: 0,
            // 控件纵坐标位置
            posY: 0,
            // 页面横轴滑动距离
            scrollX: 0,
            // 页面横轴滚动条宽度距离
            scrollXH: 0,
            // 页面纵轴滑动距离
            scrollY: 0,
            // 页面纵轴滚动条宽度距离
            scrollYW: 0,
            // 渲染动画标识
            requestAnimationFrameFlag: 0,
            // 剪切列表
            cutList: [],
            // 控件显隐
            localVisible: false,
            // 默认剪切样式
            localCutSelectors: [
                ".ivu-modal-content",
                ".ivu-modal-content .ivu-select-dropdown",
                ".ivu-modal-content .ivu-date-picker-transfer",
                ".ivu-drawer",
                ".skin-box.active",
                ".ivu-message-notice-content",
                ".user-wrap.active .user-info",
                ".no-dssclient-tips",
            ],
            // 展示安装提示
            showInstallTip: false,
            //加载中显示状态
            loading: true,
            // 显示刷新按钮
            showBtn: false,
        };
    },
    computed: {
        // 实时流
        realTimeChannelIdsStr() {
            return JSON.stringify(this.realTimeChannelIds);
        },
        // 录像属性
        recordVideoPropertyStr() {
            return JSON.stringify(this.recordVideoProperty);
        },
        // domId
        comDomId() {
            return this.domId ? this.domId : this.ctrlId;
        },
        // 剪裁客户端的Dom选择器
        comCutSeletors() {
            let arr = [...this.localCutSelectors, ...this.cutSelectors];
            return [...new Set(arr)];
        },
        // 根据videoType获取控件属性 对接客户端协议部分
        getCtrlProperty() {
            let ctrlProperty = {};
            switch (Number(this.videoType)) {
                //打开指定点位的实时视频时,设置对应ctrlProperty
                case 1:
                    ctrlProperty = {
                        displayMode: this.videoType,
                        splitNum: parseInt(this.splitNum),
                        channelList: this.realTimeChannelIds.map((i) => {
                            return { channelId: i };
                        }),
                    };
                    break;
                //打开指定点位的录像视频时,设置对应ctrlProperty
                case 2:
                    ctrlProperty = {
                        displayMode: this.videoType,
                        splitNum: parseInt(this.splitNum),
                        channelList: this.recordVideoProperty,
                    };
                    break;
                // 打开移植客户端中的实时预览全模块时，设置对应ctrlProperty
                case 3:
                    ctrlProperty = {
                        destinationCode: this.destinationCode,
                        toolBarFucList: this.toolBarFucList,
                    };
                    break;
                // 打开移植客户端中的视频回放全模块，设置对应ctrlProperty
                case 4:
                    //视频插件在创建录像回放模块实例时,有播放内容参数存在时带参数创建
                    if (this.PlaybackContent) {
                        ctrlProperty = {
                            destinationCode: this.destinationCode,
                            toolBarFucList: this.toolBarFucList,
                            searchPersonCode: this.searchPersonCode,
                            taskMenuCode: this.taskMenuCode,
                            cutInLineBtnCode: this.cutInLineBtnCode,
                            PlaybackContent: Object.assign(
                                {},
                                this.PlaybackContent
                            ),
                        };
                    } else {
                        ctrlProperty = {
                            destinationCode: this.destinationCode,
                            toolBarFucList: this.toolBarFucList,
                            taskMenuCode: this.taskMenuCode,
                            cutInLineBtnCode: this.cutInLineBtnCode,
                            searchPersonCode: this.searchPersonCode,
                        };
                    }
                    break;
                // 打开智能分析中的事件规则配置时，设置对应ctrlProperty
                case 5:
                    ctrlProperty = {
                        displayMode: 2,
                        splitNum: 1,
                        ...this.algorithmOption,
                        // algorithmType: this.algorithmOption.algorithmType || 2,
                        // algorithmCode: this.algorithmOption.algorithmCode || "",
                        // algorithmId: this.algorithmOption.algorithmId || "",
                        // channalId: this.algorithmOption.channelId || "",
                        // channelName: this.algorithmOption.channelName || "",
                        // channelAbilityPO:
                        //     this.algorithmOption.channelAbilityPO || {},
                        // videoConfigRuleProperty:
                        //     this.algorithmOption.videoConfigRuleProperty || {},
                    };
                    break;
                // 打开指定点位的图片流播放时,设置对应ctrlProperty
                case 6:
                    ctrlProperty = {
                        splitNum: parseInt(this.splitNum),
                    };
                    break;
                // 打开事件中心，ctrlType设置为AlarmManageUI
                case 7:
                    ctrlProperty = {
                        destinationCode: this.destinationCode,
                        toolBarFucList: this.toolBarFucList,
                    };
                    break;
                // 打开体温检测模块，设置对应ctrlProperty
                case 8:
                    ctrlProperty = {
                        destinationCode: this.destinationCode,
                        toolBarFucList: this.toolBarFucList,
                    };
                    break;
                // 打开下载中心，设置对应ctrlProperty
                case 9:
                    ctrlProperty = {
                        destinationCode: this.destinationCode,
                        toolBarFucList: this.toolBarFucList,
                    };
                    break;
                // 打开纪委下载刻录，设置对应ctrlProperty
                case 10:
                    ctrlProperty = {
                        destinationCode: this.destinationCode,
                        toolBarFucList: this.toolBarFucList,
                    };
                    break;

                // 视频上墙
                case 11:
                    ctrlProperty = {};
                    break;

                default:
                    break;
            }
            return ctrlProperty;
        },
        // 根据videoType获取控件类型 对接客户端协议部分
        getCtrlType() {
            let ctrlType = "";
            switch (Number(this.videoType)) {
                //打开指定点位的实时视频时,ctrlType设置playerWin
                case 1:
                    ctrlType = "playerWin";
                    break;
                //打开指定点位的录像视频时,ctrlType设置playerWin
                case 2:
                    ctrlType = "playerWin";
                    break;
                // 打开移植客户端中的实时预览功能时，ctrlType设置realMonitorUI
                case 3:
                    ctrlType = "realMonitorUI";
                    break;
                // 打开移植客户端中的视频回放功能时，ctrlType设置playbackUI
                case 4:
                    ctrlType = "playbackUI";
                    break;
                // 打开解析中心的行为事件规则配置时，ctrlType设置ruleConfigPlayer
                case 5:
                    ctrlType = "ruleConfigPlayer";
                    break;
                // 图片流播放控件时，ctrlType设置picWin
                case 6:
                    ctrlType = "picWin";
                    break;
                // 打开事件中心，ctrlType设置为AlarmManageUI
                case 7:
                    ctrlType = "AlarmManageUI";
                    break;
                // 打开体温检测模块，ctrlType设置为BodyTempMonitorUI
                case 8:
                    ctrlType = "BodyTempMonitorUI";
                    break;
                // 打开下载中心模块，ctrlType设置为DownloadCenterUI
                case 9:
                    ctrlType = "DownloadCenterUI";
                    break;
                // 打开纪委下载刻录模块，ctrlType设置为DownloadBurnUI
                case 10:
                    ctrlType = "DownloadBurnUI";
                    break;

                // 视频上墙
                case 11:
                    ctrlType = "TVWallUI";
                    break;
                default:
                    break;
            }
            return ctrlType;
        },
    },
    watch: {
        ctrlId(newValue, oldValue) {
            this.changeProp("ctrlId");
        },
        comCutSeletors: {
            handler(val) {
                console.log("ws-vue:客户端剪裁区域关联的Dom选择器:", val);
                if (val.length) {
                    this.intervalAnimationFrame();
                } else {
                    this.cutList = [];
                    this.setProperty();
                    window.cancelAnimationFrame(this.requestAnimationFrameFlag);
                    this.requestAnimationFrameFlag = 0;
                }
            },
            immediate: true,
            deep: true,
        },
        splitNum() {
            this.changeProp("splitNum");
        },
        visible() {
            this.changeProp("visible");
        },
        realTimeChannelIdsStr() {
            this.changeProp("realTimeChannelIds");
        },
        recordVideoPropertyStr() {
            this.changeProp("recordVideoProperty");
        },
        PlaybackContent() {
            console.log("vue-ws:PlaybackContent 参数发生变化");
            this.changeProp("PlaybackContent");
        },
    },
    methods: {
        /**
         * @description 调用创建控件接口
         */
        create() {
            const params = [
                {
                    ctrlType: this.getCtrlType,
                    ctrlCode: this.ctrlId,
                    domId: this.comDomId,
                    ctrlProperty: this.getCtrlProperty,
                    visible: this.visible,
                    cutList: this.cutList,
                },
            ];
            console.log("vue-ws: start create", params);
            this.$ws.createCtrl(params).catch((e) => {
                this.catchError(e);
            });
        },
        /**
         * @description 调用设置控件属性接口  修改剪切属性
         */
        setProperty() {
            console.log("ws-vue:start setProperty");
            //if(!this.comCutSeletors.length  || !(this.isCreateSuccess || this.localVisible) ) {
            if (!this.comCutSeletors.length || !this.localVisible) {
                return false;
            }
            const params = [
                {
                    ctrlCode: this.ctrlId,
                    cutList: this.cutList,
                },
            ];
            this.$ws.setCtrlPos(params).catch((e) => {
                this.catchError(e);
            });
        },
        /**
         * @description 调用设置控件显隐接口
         */
        setVisible() {
            const params = [
                {
                    ctrlCode: this.ctrlId,
                    visible: this.localVisible,
                },
            ];
            this.$ws.setCtrlVisible(params).catch((e) => {
                this.catchError(e);
            });
        },
        /**
         * @description 调用控件实时播放接口,当videoType=1时，需要调用该方法打开对应点位的实时视频
         */
        realTimeVideo() {
            const params = {
                ctrlCode: this.ctrlId,
                channelIds: this.realTimeChannelIds,
            };
            this.$ws.openCtrlPreview(params).catch((e) => {
                this.catchError(e);
            });
        },
        /**
         * @description 调用控件录像播放接口，当videoType=2时，需要调用该方法打开对应点位的录像视频
         */
        recordVideo() {
            const params = [
                {
                    ctrlCode: this.ctrlId,
                    array: this.recordVideoProperty,
                },
            ];
            this.$ws.openCtrlRecord(params).catch((e) => {
                this.catchError(e);
            });
        },

        /**
         * @description 改变传参
         */
        changeProp(type) {
            switch (type) {
                case "visible": //控件显隐控制
                    this.localVisible = this.visible;
                    this.setVisible();
                    break;
                case "ctrlId": //控件实例变更，重新控件位置，需要确保新的控件ID已经创建实例
                    this.setProperty();
                    break;
                case "realTimeChannelIds": //实时视频，指定通道列表
                    this.realTimeVideo();
                    break;
                case "recordVideoProperty": //录像回放，指定通道列表和时间段
                    this.recordVideo();
                    break;
                case "PlaybackContent": // 录像回放模块，指定打开图播/录像界面，任务ID（图播时）,图片信息(图播时),通道列表,时间段,播放时间
                    this.updatePlaybackContent();
                    break;
                default:
                    this.$emit("changeProp", this.ctrlId, this.type);
            }
        },

        /**
         * @description 循环监听动画帧，执行剪切功能
         */
        intervalAnimationFrame() {
            console.log("vue-ws: start intervalAnimationFrame");
            let _this = this;
            if (_this.requestAnimationFrameFlag) {
                console.log(
                    "vue-ws: requestAnimationFrameFlag is exist return"
                );
                return;
            }
            this.$nextTick(() => {
                (function step() {
                    _this.requestAnimationFrameFlag = window.requestAnimationFrame(
                        step
                    );
                    //if(_this.comCutSeletors.length > 0 && (_this.isCreateSuccess || _this.localVisible)) {
                    if (_this.comCutSeletors.length > 0 && _this.localVisible) {
                        _this.calcCutArea();
                    }
                })();
            });
        },

        /**
         * @description 重定位组件
         */
        relocated() {
            this.$ws.reLocatedPosition([this.ctrlId]).catch((e) => {
                this.catchError(e);
            });
        },

        /**
         * @description 计算重叠区域
         */
        calcCutArea() {
            // 没有要剪切的元素，或者当前控件没有创建成功/不存在/隐藏，直接返回
            //if(!(this.comCutSeletors.length && (this.isCreateSuccess || this.localVisible) && this.$ws.ids.includes(this.ctrlId) && this.$ws.ctrls.filter(e => {return e.ctrlCode === this.ctrlId})[0].visible)) {
            if (
                !(
                    this.comCutSeletors.length &&
                    this.localVisible &&
                    this.$ws.ids.includes(this.ctrlId) &&
                    this.$ws.ctrls.filter((e) => {
                        return e.ctrlCode === this.ctrlId;
                    })[0].visible
                )
            ) {
                return false;
            }
            let _this = this;
            let _result = [];
            let domList = [];
            this.comCutSeletors.forEach((e) => {
                let select = document.querySelectorAll(e);
                try {
                    // 在iframe中为父元素增加标识
                    if (
                        self.frameElement &&
                        self.frameElement.tagName == "IFRAME"
                    ) {
                        let parentSelect = parent.document.querySelectorAll(e);
                        parentSelect.forEach((item) => {
                            item.inIframe = true;
                        });
                        select = [...select, ...parentSelect];
                    }
                } catch (error) {
                    console.log("ws-vue:子窗口和顶层窗口非同源:", error);
                }
                select.forEach((d) => {
                    domList.push(d);
                });
            });
            domList.forEach((d) => {
                let _item = [];
                try {
                    // 在iframe中且是父元素计算结果需要增加iframe的影响
                    if (
                        self.frameElement &&
                        self.frameElement.tagName == "IFRAME" &&
                        d.inIframe
                    ) {
                        _item = _this.calcSingle(
                            d,
                            self.frameElement.getClientRects()[0]
                        );
                    } else {
                        _item = _this.calcSingle(d);
                    }
                } catch (error) {
                    console.log("ws-vue:子窗口和顶层窗口非同源:", error);
                }
                if (_item.length) {
                    _result.push(_item);
                }
            });
            let _cutList = [];
            _result.forEach((e) => {
                let element = document.getElementById(this.ctrlId);
                if (!(element && element.getClientRects().length)) {
                    return false;
                }
                const elementRect = element.getClientRects()[0];
                let element_posX1 = elementRect.x;
                let element_posY1 = elementRect.y;
                let element_posX2 =
                    element_posX1 + Math.floor(elementRect.width);
                let element_posY2 =
                    element_posY1 + Math.floor(elementRect.height);
                let cutPosX = e[0] - element_posX1;
                let cutPosY = e[2] - element_posY1;
                let cutWidth = e[1] - e[0];
                let cutHeight = e[3] - e[2];
                _cutList.push({
                    posX: cutPosX,
                    posY: cutPosY,
                    width: cutWidth,
                    height: cutHeight,
                });
            });
            let lastCutListStr = JSON.stringify(this.cutList);
            let currentCutListStr = JSON.stringify(_cutList);
            // 有变更的切割区域，才发送消息
            if (lastCutListStr !== currentCutListStr) {
                _this.cutList = _cutList;
                _this.setProperty();
            }
        },
        /**
         * @description 计算单个DOM和客户端控件的重叠区域
         */
        calcSingle(dom, frameElementDom) {
            let _dom = dom;
            if (!_dom.getClientRects().length) return [];
            // 当前组件的位置
            let element = document.getElementById(this.ctrlId);
            if (!(element && element.getClientRects().length)) {
                return false;
            }
            const elementRect = element.getClientRects()[0];
            let element_posX1 = elementRect.x;
            let element_posY1 = elementRect.y;
            let element_posX2 = element_posX1 + Math.floor(elementRect.width);
            let element_posY2 = element_posY1 + Math.floor(elementRect.height);
            // 监听的组件位置
            if (_dom.getClientRects().length === 0) {
                // DOM不存在
                return false;
            }
            const _domRect = _dom.getClientRects()[0];
            let _dom_posX1 = frameElementDom
                ? _domRect.x - frameElementDom.x
                : _domRect.x;
            let _dom_posY1 = frameElementDom
                ? _domRect.y - frameElementDom.y
                : _domRect.y;
            let _dom_posX2 = _dom_posX1 + Math.floor(_domRect.width);
            let _dom_posY2 = _dom_posY1 + Math.floor(_domRect.height);
            // 重叠判断：如果X轴坐标有交集且Y轴坐标有交集
            // 单轴重叠判断为Max(dom1.x1, dom2.x1) < Min(dom1.x2, dom1.x2), 重叠范围为[Max(dom1.x1, dom2.x1), Min(dom1.x2, dom1.x2)]
            if (
                Math.max(element_posX1, _dom_posX1) <
                    Math.min(element_posX2, _dom_posX2) &&
                Math.max(element_posY1, _dom_posY1) <
                    Math.min(element_posY2, _dom_posY2)
            ) {
                // 有交集
                return [
                    Math.max(element_posX1, _dom_posX1),
                    Math.min(element_posX2, _dom_posX2),
                    Math.max(element_posY1, _dom_posY1),
                    Math.min(element_posY2, _dom_posY2),
                ];
            } else {
                return [];
            }
        },
        /**
         * @description 销毁控件
         */
        destroy() {
            if (this.keepAlive) {
                // 保活时，只是隐藏
                this.localVisible = false;
                this.$ws.setCtrlVisible([
                    {
                        ctrlCode: this.ctrlId,
                        visible: this.localVisible,
                    },
                ]);
                // 停止视频拉流和关闭声音
                this.$ws.transparent("closeCtrl", { ctrlCode: this.ctrlId });
            } else {
                // 不保活，直接销毁
                this.$ws.destroyCtrl([this.ctrlId]).catch((e) => {
                    this.catchError(e);
                });
            }
            window.cancelAnimationFrame(this.requestAnimationFrameFlag);
            this.requestAnimationFrameFlag = 0;
        },
        /**
         * @description 调用方法异常
         * @param { Number } e 异常类型
         */
        catchError(e) {
            this.loading = false;
            if (e === 1) {
                this.$emit("connectFail");
            } else {
                this.$emit("loginFail");
            }
        },
        handleControl(iskeepAlive = true) {
            this.loading = true;
            if (
                (iskeepAlive || this.keepAlive) &&
                this.$ws.ids.includes(this.ctrlId)
            ) {
                this.localVisible = true;
                const params = [
                    {
                        ctrlCode: this.ctrlId,
                        visible: this.localVisible,
                    },
                ];
                console.log(
                    "vue-ws: start handleControl and iskeepAlive is true"
                );
                // 连接成功之后，进行版本比对
                !iskeepAlive &&
                    this.$ws.isConnectSuccessQt &&
                    this.$ws.compareVersion();
                // 当视频插件被缓存时，带参打开录像回放模块时，参数需要手动传输
                if (
                    !iskeepAlive &&
                    Number(this.videoType) === 4 &&
                    this.PlaybackContent
                ) {
                    let params = Object.assign({}, this.PlaybackContent);
                    this.$ws
                        .transparent("updatePlaybackContent", params)
                        .catch((e) => {
                            this.catchError(e);
                        });
                }
                this.$ws
                    .setCtrlVisible(params)
                    .then(() => {
                        // 计算控件位置
                        this.$ws.reLocatedPosition([this.ctrlId]);
                        // 同样使用创建成功的逻辑
                        this.$emit("createSuccess");
                        var lastCutList = JSON.parse(
                            window.sessionStorage.getItem(
                                `$ws_${this.ctrlId}_cutList`
                            )
                        );
                        if (lastCutList) {
                            this.cutList = lastCutList;
                        }
                        // 保活且控件未销毁时，设置控件剪裁区域
                        console.log(
                            "vue-ws:handleControl func call intervalAnimationFrame"
                        );
                        this.intervalAnimationFrame();
                        this.loading = false;
                    })
                    .catch((e) => {
                        this.catchError(e);
                    });
            } else {
                console.log("vue-ws: start handleControl and create ctrl");
                // 不保活/或者保活后被其他因素销毁，重新创建
                this.localVisible = this.visible;
                this.create();
            }

            // 加载中6秒后再出现刷新的按钮
            setTimeout(() => {
                this.showBtn = true;
            }, 6000);
        },
        downloadDss() {
            this.$ws && this.$ws.downloadClient();
        },
        refreshFun() {
            window.location.reload();
        },

        /**
         * 更新录像回放模块的参数内容
         */
        updatePlaybackContent() {
            console.log(
                "vue-ws: start updatePlaybackContent",
                this.PlaybackContent
            );

            if (Number(this.videoType) === 4 && this.PlaybackContent) {
                let params = Object.assign({}, this.PlaybackContent);
                console.log("params:", params);
                this.$ws
                    .transparent("updatePlaybackContent", params)
                    .catch((e) => {
                        this.catchError(e);
                    });
            }
        },
        /**
         * 控件创建成功事件处理函数
         */
        createCtrlResult(res) {
            this.loading = false;
            // 有此组件的创建结果
            if (res[0].ctrlCode == this.ctrlId) {
                if (res[0].result === 0) {
                    this.setProperty();
                    this.$emit("createSuccess");
                } else {
                    this.$emit("createFail");
                }
            }
        },
        /**
         * 链接事件变更事件处理函数
         */
        connectStateChange(res) {
            if (res) {
                this.showInstallTip = false;
            } else {
                this.loading = false;
                this.showInstallTip = true;
            }
        },
    },
    activated() {
        console.log("vue-ws: start activated");
        // 兼容mcm框架切换菜单时，立即获取元素没有实际宽高的bug，使用宏任务
        this.$nextTick(() => {
            setTimeout(() => {
                this.handleControl(true);
            }, 100);
        });
    },
    deactivated() {
        this.requestAnimationFrameFlag &&
            window.cancelAnimationFrame(this.requestAnimationFrameFlag);
        this.requestAnimationFrameFlag = 0;
        this.localVisible = false;
        window.sessionStorage.setItem(
            `$ws_${this.ctrlId}_cutList`,
            JSON.stringify(this.cutList)
        );
        if (this.$ws.ids.includes(this.ctrlId)) {
            this.$ws.setCtrlVisible([
                {
                    ctrlCode: this.ctrlId,
                    visible: this.localVisible,
                },
            ]);
        }
    },
    mounted() {
        console.log("vue-ws: start mounted");
        // 兼容mcm框架切换菜单时，立即获取元素没有实际宽高的bug，使用宏任务
        this.$nextTick(() => {
            setTimeout(() => {
                this.handleControl(false);
            }, 100);
        });
    },
    beforeDestroy() {
        if (this.keepAlive) {
            window.sessionStorage.setItem(
                `$ws_${this.ctrlId}_cutList`,
                JSON.stringify(this.cutList)
            );
        }
        this.destroy();

        this.$ws.removeEventListener("createCtrlResult", this.createCtrlResult);
        this.$ws.removeEventListener(
            "connectStateChange",
            this.connectStateChange
        );
    },
    created() {
        this.$ws.addEventListener("createCtrlResult", this.createCtrlResult);
        this.$ws.addEventListener(
            "connectStateChange",
            this.connectStateChange
        );
    },
};
</script>

<style lang="less" scoped>
.wsvue {
    .no-client {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        .anim {
            width: 600px;
            height: 400px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .anim-bg {
                margin-bottom: 20px;
                width: 254px;
                height: 212px;
                background: url("img/no-client.png") center no-repeat;
            }
        }
        i {
            font-style: normal;
            color: var(--primary);
            cursor: pointer;
        }
    }

    .client-loading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 10;
        position: relative;
        .anim {
            width: 600px;
            height: 400px;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .refresh-btn {
            position: absolute;
            top: 50%;
            margin-top: 0.5rem;
            cursor: pointer;
            z-index: 999;
        }
        /deep/ .ivu-spin-custom-dot-custom {
            margin: auto;
        }
    }
}
</style>
